import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../../components/layout"
import ColoredHeader from "../../../components/coloredHeader"
import FloatingSearch from "../../../components/floatingSearch"
import Button from "@material-ui/core/Button"
import atlas_lg_blackwhite from "../../../../static/background_images/atlas_lg_blackwhite.jpg"
import AreasOfOperationImage from "../../../../static/images/EOG-Areas-Operation.png"
import AustraliaImage from "../../../../static/images/Australia"
import { Link } from "../../../components/CustomLinks"

export default function AreasOfOperation(props) {
  return (
    <Layout>
      <Helmet>
        <title>Areas of Operation – EOG Resources, Inc.</title>
      </Helmet>
      <ColoredHeader
        title="COMPANY"
        header="Areas of Operation"
        overlayColor1="#d50000"
        overlayColor2="#da3433"
        lineColor="#f95c4f"
        bgImageSRC={atlas_lg_blackwhite}
      />
      <div className="max-width-container absolute desktop-only">
        <FloatingSearch clear />
      </div>
      <div className="page areas-of-operation-page">
        <div className="max-width-container">
          <a href={AreasOfOperationImage} target="_aaoc">
            <img
              alt="areas of operation"
              src={AreasOfOperationImage}
              style={{
                width: "100%",
                maxWidth: "500%",
                height: "auto",
              }}
            />
          </a>
        </div>
      </div>

      <div className="page areas-of-operation-page australia-map last-page">
        <div
          style={{
            height: 400,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 80,
          }}
        >
          <AustraliaImage />
          <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
            <h2>Australia</h2>
            <Button
              component={Link}
              className="areas-of-operation-page-button"
              to="/company/areas-of-operation/australia"
            >
              Learn More
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  )
}
